import { render, staticRenderFns } from "./Blogs.vue?vue&type=template&id=44ed08c9&scoped=true&"
import script from "./Blogs.vue?vue&type=script&lang=ts&"
export * from "./Blogs.vue?vue&type=script&lang=ts&"
import style0 from "./Blogs.vue?vue&type=style&index=0&id=44ed08c9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ed08c9",
  null
  
)

export default component.exports